import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Container from "../components/Container"
const NotFoundPage = () => {
  const d = useStaticQuery(graphql`
    query GET_404_Image {
      file(name: { eq: "404" }) {
        childImageSharp {
          img: fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
    }
  `)
  const styles = {
    maxWidth: "300px",
    borderRadius: "50%",
    height: "100%",
  }
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <div className="flex flex-col items-center mx-auto mt-10 ">
          <Img
            fluid={d.file.childImageSharp.img}
            objectFit="center"
            className="max-w-xxs"
            style={{ borderRadius: "50%" }}
          />
          {
            <div className="py-5 font-normal text-center text-text-secondary1">
              <h1 className="text-xl font-medium md:text-2-5xl">
                PAGE NOT FOUND
              </h1>

              <p className="my-5 text-sm md:text-base">
                Sorry You just hit a route that doesn&#39;t exist...
                <br /> Let us take you back to the homepage
              </p>
              <Link to="/" className="button gradient-primary" replace>
                Take me back
              </Link>
            </div>
          }
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
